import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, shareReplay } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

import moment from 'moment';

import { environment as env } from '../../environments/environment';
import { User } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  private _userSubject = new BehaviorSubject<User | null>(null);
  user$ = this._userSubject.asObservable();

  private _redirected = false;

  constructor(private readonly _http: HttpClient, private readonly _router: Router) {}

  baseUrl = env.authority + '/authorize';
  responseType = 'code';
  scope = env.scopes.join(' ');
  clientId = env.clientId;
  redirectUrl = env.redirectUrl;
  state = this.generateState();

  url = `${this.baseUrl}?response_type=${this.responseType}&scope=${this.scope}&client_id=${this.clientId}&state=${this.state}&redirect_uri=${this.redirectUrl}`;

  generateState(): string {
    const length = 16;
    let result = '';
    const charsLength = this._chars.length;

    for (let i = 0; i < length; i++) {
      result += this._chars.charAt(Math.floor(Math.random() * charsLength));
    }
    return result;
  }

  login() {
    window.location.href = this.url;
  }

  getAccessToken(code: string, state: string) {
    const url = `${env.apiUrl}Auth/GetUserToken`;
    this._http
      .get(url, {
        params: {
          authorization_code: code,
          state: state,
          redirect_uri: env.redirectUrl,
        },
      })
      .subscribe({
        next: (response: object) => {
          const tokenResponse = response as { access_token: string };
          this.setSession(tokenResponse.access_token);
          this.redirectUser();
        },
        error: (error) => {
          console.error('Error from backend:', error);
          this._router.navigate(['/login'], {
            queryParams: { error: error.message },
          });
        },
      });
    shareReplay();
  }

  redirectUser(): void {
    const user = this._userSubject.getValue();
    if (user?.role === 'gp') {
      setTimeout(() => {
        this._router.navigate(['/home']);
      }, 0);
    } else {
      this._router.navigate(['/role']);
    }
  }

  setSession(token: string): void {
    sessionStorage.setItem('access_token', token);
    const decodedToken: {
      role: string;
      display_name: string;
      selected_orgname: string;
    } = jwtDecode(token);
    const user: User = {
      role: decodedToken.role.toLocaleLowerCase(),
      username: decodedToken.display_name,
      organisation: decodedToken.selected_orgname,
    };
    this._userSubject.next(user);
  }

  signOut() {
    sessionStorage.removeItem('access_token');
    this._userSubject.next(null);
    this._router.navigate(['/login']);
  }

  public isLoggedIn(): boolean {
    const expiration = this.getExpiration();
    if (!expiration) {
      return false;
    }
    return expiration.isValid() && moment().isBefore(expiration);
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration(): moment.Moment | false {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      return false;
    }
    const decoded: { exp: number } = jwtDecode(token);
    return moment.unix(decoded.exp);
  }
}
