<div class="bg-blue">
  <div class="m-w-1200 mx-auto flex space-between py-4 text-white">
    <div class="flex gap-16">
      @if (user?.username) {
      <span>{{ user?.username }}</span>
      } @if (user?.role === 'gp') {
      <span>GP</span>
      } @if (user?.role === 'hv' ) {
      <span>Health visiting team</span>
      } @if (user?.role === 'sn') {
      <span>School age Immunisation team</span>
      } @if (user?.organisation){
      <span>{{ user?.organisation }}</span>
      }
    </div>
    <div class="flex gap-16">
      @if (user?.role === 'hv' || user?.role === 'sn') {
      <a routerLink="/role">Change role</a>
      }
      <button class="nhsuk-link" (click)="signOut()">Sign out</button>
    </div>
  </div>
</div>
