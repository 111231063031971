<header class="nhsuk-header nhsuk-header--organisation" role="banner">
  <div class="nhsuk-header__container-fluid px-0 py-24">
    <div class="nhsuk-header__logo">
      <a
        class="nhsuk-header__link"
        href="/"
        aria-label="Child Health Information Service Healthcare Professional Portal homepage"
      >
        <svg class="nhsuk-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 16" height="40" width="100">
          <path class="nhsuk-logo__background" fill="#005eb8" d="M0 0h40v16H0z"></path>
          <path
            class="nhsuk-logo__text"
            fill="#fff"
            d="M3.9 1.5h4.4l2.6 9h.1l1.8-9h3.3l-2.8 13H9l-2.7-9h-.1l-1.8 9H1.1M17.3 1.5h3.6l-1 4.9h4L25 1.5h3.5l-2.7 13h-3.5l1.1-5.6h-4.1l-1.2 5.6h-3.4M37.7 4.4c-.7-.3-1.6-.6-2.9-.6-1.4 0-2.5.2-2.5 1.3 0 1.8 5.1 1.2 5.1 5.1 0 3.6-3.3 4.5-6.4 4.5-1.3 0-2.9-.3-4-.7l.8-2.7c.7.4 2.1.7 3.2.7s2.8-.2 2.8-1.5c0-2.1-5.1-1.3-5.1-5 0-3.4 2.9-4.4 5.8-4.4 1.6 0 3.1.2 4 .6"
          ></path>
        </svg>

        <span class="nhsuk-organisation-name"> Child Health Information Service Healthcare Professional Portal </span>
        <span class="nhsuk-organisation-descriptor">NHS South Central and West</span>
      </a>
    </div>
  </div>

  <div class="nhsuk-navigation-container">
    <nav class="nhsuk-navigation" id="header-navigation" role="navigation" aria-label="Primary navigation">
      <ul class="nhsuk-header__navigation-list m-w-1200 flex-start gap-32 px-0">
        @for (navItem of mainNavigation; track $index) {
        <li class="nhsuk-header__navigation-item">
          <a class="nhsuk-header__navigation-link" [routerLink]="'/' + navItem.url">
            {{ navItem.name }}
          </a>
        </li>
        }

        <li class="nhsuk-mobile-menu-container">
          <button
            class="nhsuk-header__menu-toggle nhsuk-header__navigation-link"
            id="toggle-menu"
            aria-expanded="false"
          >
            <span class="nhsuk-u-visually-hidden">Browse</span>
            More
            <svg
              class="nhsuk-icon nhsuk-icon__chevron-down"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
            >
              <path
                d="M15.5 12a1 1 0 0 1-.29.71l-5 5a1 1 0 0 1-1.42-1.42l4.3-4.29-4.3-4.29a1 1 0 0 1 1.42-1.42l5 5a1 1 0 0 1 .29.71z"
              ></path>
            </svg>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</header>
