import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { AuthService } from '../../services';
import { User } from '../../models';

@Component({
  selector: 'app-status-bar',
  imports: [RouterLinkWithHref],
  templateUrl: './status-bar.component.html',
  styleUrl: './status-bar.component.css',
})
export class StatusBarComponent implements OnInit {
  user: User | null = null;

  constructor(
    private _authService: AuthService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this._authService.user$.subscribe((user) => {
      this.user = user;
      this._cdr.detectChanges();
    });
  }

  signOut() {
    this._authService.signOut();
  }
}
