import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services';

export const AuthInterceptor: HttpInterceptorFn = (req, next) => {
  const idToken = sessionStorage.getItem('access_token');

  if (idToken) {
    const authService = inject(AuthService);

    if (!authService.isLoggedIn()) {
      sessionStorage.removeItem('access_token');
      const router = inject(Router);
      router.navigate(['/login'], {
        queryParams: { error: 'Session Expired' },
      });
      return next(req); // Optionally, you can return an empty observable to stop the request
    }

    const cloned = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + idToken),
    });

    return next(cloned);
  } else {
    return next(req);
  }
};
