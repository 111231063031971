export const environment = {
  production: false,
  apiUrl: 'https://chis-hcp-dev.scwcsu.nhs.uk/api/',
  clientId: '8924008144.chis_dev.f51e2259-29a9-4ebc-b4e7-5c2147cf6bb3.apps',
  authority:
    'https://am.nhsint.auth-ptl.cis2.spineservices.nhs.uk:443/openam/oauth2/realms/root/realms/NHSIdentity/realms/Healthcare',
  responseType: 'code',
  scopes: ['openid', 'profile', 'email', 'nhsperson', 'associatedorgs', 'nationalrbacaccess'],
  redirectUrl: 'https://chis-hcp-dev.scwcsu.nhs.uk/login',
};
