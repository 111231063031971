import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map, take } from 'rxjs';
import { AuthService } from '../services';

enum Role {
  GP = 'gp',
  HealthVisitor = 'HV',
  SchoolImmunisationTeam = 'SC',
}

export const roleGuard: CanActivateFn = () => {
  const _authService = inject(AuthService);

  const router = inject(Router);

  return _authService.user$.pipe(
    take(1),
    map((user) => {
      if (user && isValidRole(user?.role)) {
        return true;
      } else {
        router.navigateByUrl('/role');
        return true;
      }
    })
  );

  function isValidRole(value: string): value is Role {
    return Object.values(Role).includes(value.toUpperCase() as Role);
  }
};
