import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services';

export const authGuard: CanActivateFn = () => {
  const _authService = inject(AuthService);
  const _router = inject(Router);

  if (_authService.isLoggedIn()) {
    return true;
  } else {
    _router.navigate(['/login'], {
      queryParams: { error: 'Session Expired' },
    });
    return false;
  }
};
