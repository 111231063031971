import { Routes } from '@angular/router';
import { authGuard } from './guards';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.component').then((component) => component.LoginComponent),
  },
  {
    path: 'role',
    loadComponent: () => import('./pages/role/role.component').then((Component) => Component.RoleComponent),
    canActivate: [authGuard],
  },
  {
    path: 'home',
    loadComponent: () => import('./pages/home/home.component').then((component) => component.HomeComponent),
    canActivate: [authGuard],
  },
  {
    path: 'accessibility',
    loadComponent: () =>
      import('./pages/accessibility/accessibility.component').then((component) => component.AccessibilityComponent),
  },
  {
    path: 'feedback',
    loadComponent: () => import('./pages/feedback/feedback.component').then((component) => component.FeedbackComponent),
  },
  {
    path: 'cookies',
    loadComponent: () => import('./pages/cookies/cookies.component').then((component) => component.CookiesComponent),
  },
  {
    path: 'info',
    loadComponent: () => import('./pages/info/info.component').then((component) => component.InfoComponent),
  },
  {
    path: 'terms',
    loadComponent: () => import('./pages/terms/terms.component').then((component) => component.TermsComponent),
  },

  {
    path: 'vaccine-history',
    loadComponent: () =>
      import('./pages/vaccine-history/vaccine-history.component').then(
        (Component) => Component.VaccineHistoryComponent
      ),
  },
  {
    path: 'vaccine-history/patient/:guid',
    loadComponent: () =>
      import('./pages/patient-record/patient-record.component').then((Component) => Component.PatientRecordComponent),
    canActivate: [authGuard],
    children: [
      {
        path: '',
        redirectTo: 'demographic',
        pathMatch: 'full',
      },
      {
        path: 'demographic',
        loadComponent: () =>
          import('./components/patient/demographic/demographic.component').then((c) => c.DemographicComponent),
      },
      {
        path: 'language',
        loadComponent: () =>
          import('./components/patient/language/language.component').then((c) => c.LanguageComponent),
      },
      {
        path: 'contact',
        loadComponent: () => import('./components/patient/contact/contact.component').then((c) => c.ContactComponent),
      },
      {
        path: 'school',
        loadComponent: () => import('./components/patient/school/school.component').then((c) => c.SchoolComponent),
      },
      {
        path: 'names',
        loadComponent: () => import('./components/patient/names/names.component').then((c) => c.NamesComponent),
      },
      {
        path: 'details',
        loadComponent: () => import('./components/patient/details/details.component').then((c) => c.DetailsComponent),
      },
      {
        path: 'flags',
        loadComponent: () => import('./components/patient/flags/flags.component').then((c) => c.FlagsComponent),
      },
      {
        path: 'blood-spot-screening',
        loadComponent: () =>
          import('./components/patient/blood-spot-screening/blood-spot-screening.component').then(
            (c) => c.BloodSpotScreeningComponent
          ),
      },
      {
        path: 'vaccinations',
        loadComponent: () =>
          import('./components/patient/childhood-vaccination/childhood-vaccination.component').then(
            (c) => c.ChildhoodVaccinationComponent
          ),
      },
      {
        path: 'vaccinations/:vaccineId',
        loadComponent: () =>
          import('./components/patient/childhood-vaccination-detail/childhood-vaccination-detail.component').then(
            (c) => c.ChildhoodVaccinationDetailComponent
          ),
      },
      {
        path: 'examinations',
        loadComponent: () =>
          import('./components/patient/examinations/examinations.component').then((c) => c.ExaminationsComponent),
      },
      {
        path: 'bcg',
        loadComponent: () => import('./components/patient/bcg/bcg.component').then((c) => c.BcgComponent),
      },
      {
        path: 'neo-natal-hepb',
        loadComponent: () =>
          import('./components/patient/bcg-hep-b-pathway/bcg-hep-b-pathway.component').then(
            (c) => c.BcgHepBPathwayComponent
          ),
      },
      {
        path: 'death',
        loadComponent: () => import('./components/patient/death/death.component').then((c) => c.DeathComponent),
      },
    ],
  },
  {
    path: 'search',
    loadComponent: () => import('./pages/search/search.component').then((Component) => Component.SearchComponent),
    canActivate: [authGuard],
  },
  {
    path: 'search/patient/:guid',
    loadComponent: () =>
      import('./pages/patient-record/patient-record.component').then((Component) => Component.PatientRecordComponent),
    canActivate: [authGuard],
    children: [
      {
        path: '',
        redirectTo: 'demographic',
        pathMatch: 'full',
      },
      {
        path: 'demographic',
        loadComponent: () =>
          import('./components/patient/demographic/demographic.component').then((c) => c.DemographicComponent),
      },
      {
        path: 'language',
        loadComponent: () =>
          import('./components/patient/language/language.component').then((c) => c.LanguageComponent),
      },
      {
        path: 'contact',
        loadComponent: () => import('./components/patient/contact/contact.component').then((c) => c.ContactComponent),
      },
      {
        path: 'school',
        loadComponent: () => import('./components/patient/school/school.component').then((c) => c.SchoolComponent),
      },
      {
        path: 'names',
        loadComponent: () => import('./components/patient/names/names.component').then((c) => c.NamesComponent),
      },
      {
        path: 'details',
        loadComponent: () => import('./components/patient/details/details.component').then((c) => c.DetailsComponent),
      },
      {
        path: 'flags',
        loadComponent: () => import('./components/patient/flags/flags.component').then((c) => c.FlagsComponent),
      },
      {
        path: 'blood-spot-screening',
        loadComponent: () =>
          import('./components/patient/blood-spot-screening/blood-spot-screening.component').then(
            (c) => c.BloodSpotScreeningComponent
          ),
      },
      {
        path: 'vaccinations',
        loadComponent: () =>
          import('./components/patient/childhood-vaccination/childhood-vaccination.component').then(
            (c) => c.ChildhoodVaccinationComponent
          ),
      },
      {
        path: 'vaccinations/:vaccineId',
        loadComponent: () =>
          import('./components/patient/childhood-vaccination-detail/childhood-vaccination-detail.component').then(
            (c) => c.ChildhoodVaccinationDetailComponent
          ),
      },
      {
        path: 'examinations',
        loadComponent: () =>
          import('./components/patient/examinations/examinations.component').then((c) => c.ExaminationsComponent),
      },
      {
        path: 'bcg',
        loadComponent: () => import('./components/patient/bcg/bcg.component').then((c) => c.BcgComponent),
      },
      {
        path: 'neo-natal-hepb',
        loadComponent: () =>
          import('./components/patient/bcg-hep-b-pathway/bcg-hep-b-pathway.component').then(
            (c) => c.BcgHepBPathwayComponent
          ),
      },
      {
        path: 'death',
        loadComponent: () => import('./components/patient/death/death.component').then((c) => c.DeathComponent),
      },
    ],
  },
  {
    path: '**',
    loadComponent: () =>
      import('./pages/not-found/not-found.component').then((Component) => Component.NotFoundComponent),
  },
];
